export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const cookie = parts.pop().split(';').shift()
        return cookie;
    }
}

export const setCookie = (name, value, maxAge) => {
    document.cookie = `${name}=${value}; max-age=${maxAge}`;
}

export const deleteCookie = (name) => {
    setCookie(name, "", {
      'max-age': -1
    })
  }