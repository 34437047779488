export const DesktopIcon = () => <svg viewBox="0 0 24 24" fill="currentColor"><path d="M21 2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v2H8v2h8v-2h-2v-2h7c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H3V4h18v12z" /></svg>
export const SearchMagnifier = () => <svg viewBox="0 0 250.313 250.313" fill="currentColor"><path d="M244.186,214.604l-54.379-54.378c-0.289-0.289-0.628-0.491-0.93-0.76c10.7-16.231,16.945-35.66,16.945-56.554C205.822,46.075,159.747,0,102.911,0S0,46.075,0,102.911c0,56.835,46.074,102.911,102.91,102.911c20.895,0,40.323-6.245,56.554-16.945c0.269,0.301,0.47,0.64,0.759,0.929l54.38,54.38c8.169,8.168,21.413,8.168,29.583,0C252.354,236.017,252.354,222.773,244.186,214.604zM102.911,170.146c-37.134,0-67.236-30.102-67.236-67.235c0-37.134,30.103-67.236,67.236-67.236c37.132,0,67.235,30.103,67.235,67.236C170.146,140.044,140.043,170.146,102.911,170.146z" /></svg>
export const Cross = () => <svg viewBox="0 0 13.314 13.314" fill="currentColor"><path d="M37.021-14.565l-4.95-4.95-4.95,4.95a1,1,0,0,1-1.415,0,1,1,0,0,1,0-1.415l4.95-4.949-4.95-4.95a1,1,0,0,1,0-1.414,1,1,0,0,1,1.415,0l4.95,4.949,4.95-4.949a1,1,0,0,1,1.415,0,1,1,0,0,1,0,1.414l-4.95,4.95,4.95,4.949a1,1,0,0,1,0,1.415,1,1,0,0,1-.707.293A1,1,0,0,1,37.021-14.565Z" transform="translate(-25.414 27.585)" /></svg>
export const DownloadIcon = () => (
    <svg viewBox="0 0 480 512" fill="currentColor">
        <path d="M-90,153a31.991,31.991,0,0,1-32-32V-7h64V89H294V-7h64V121a31.971,31.971,0,0,1-32,32ZM105.97,19.528l-112-128a15.962,15.962,0,0,1-2.528-17.152A16.013,16.013,0,0,1,6-135H70V-343a16.006,16.006,0,0,1,16-16h64a16.007,16.007,0,0,1,16,16v208h64a15.96,15.96,0,0,1,14.56,9.376,15.953,15.953,0,0,1-2.528,17.152l-112,128A16.058,16.058,0,0,1,118,25,15.948,15.948,0,0,1,105.97,19.528Z" transform="translate(122 359)" />
    </svg>
)
export const ShareIcon = () => (
    <svg viewBox="0 0 473.932 473.932" fill="currentColor">
        <path d="M385.513,301.214c-27.438,0-51.64,13.072-67.452,33.09l-146.66-75.002c1.92-7.161,3.3-14.56,3.3-22.347c0-8.477-1.639-16.458-3.926-24.224l146.013-74.656c15.725,20.924,40.553,34.6,68.746,34.6c47.758,0,86.391-38.633,86.391-86.348C471.926,38.655,433.292,0,385.535,0c-47.65,0-86.326,38.655-86.326,86.326c0,7.809,1.381,15.229,3.322,22.412L155.892,183.74c-15.833-20.039-40.079-33.154-67.56-33.154c-47.715,0-86.326,38.676-86.326,86.369s38.612,86.348,86.326,86.348c28.236,0,53.043-13.719,68.832-34.664l145.948,74.656c-2.287,7.744-3.947,15.79-3.947,24.289c0,47.693,38.676,86.348,86.326,86.348c47.758,0,86.391-38.655,86.391-86.348C471.904,339.848,433.271,301.214,385.513,301.214z" />
    </svg>
)
export const VisibilityIcon = () => (
    <svg viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </svg>
)