import React from "react"
import { LogoCW } from "../logos"
import styles from "./footer.module.css"

export const Footer = () => {
	return (
		<footer className={styles.footer}>
			<div className={styles.copy}>{`© Chemwatch, ${new Date().getFullYear()}`}</div>
			<div className={styles.CW_block}>	
				<LogoCW height={24} />
			</div>
		</footer>
	)
}