export const convertBase64ToBlob = (base64, contentType, params = "") => {
	const decodedData = window.atob(base64)
	const uInt8Array = new Uint8Array(decodedData.length)
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i)
	}

	const blob = new Blob([uInt8Array], { type: contentType })
	const fileURL = URL.createObjectURL(blob);
	if (params) {
		return `${fileURL}${params}`
	}

	return fileURL
}

export const getBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader()
	reader.readAsDataURL(file)
	reader.onload = () => resolve(reader.result)
	reader.onerror = error => reject(error)
})
