import Iteca from "./iteca_logo.png"
import Puma from "./puma_security.png"
import JasAnz from "./jas_anz.png"
import ISO27001 from "./ISO_27001.png"
import SOC2 from "./SOC2.png"
import NFPA from "./CFPS_PracticeExam.png"
import GCC from "./GCC.png"
import NRT from "./nationally_recognised_training.png"
import GOVEA from "./GOVEA_award_2014.png"
import ESG from "./ESG_driven.png"
import CHUFFED from "./CHUFFED.png"
import styles from "../login.module.css"

export const Awards = [
    {
        url: "https://www.chemwatch.net/environmental-social-and-governance-policy/",
        title: "Environmental, Social and Governance Policy",
        className: styles.ESG,
        src: ESG
    },
    {
        url: "https://www.iteca.edu.au/",
        title: "Independent Tertiary Education Council Australia",
        className: styles.iteca,
        src: Iteca
    },
    {
        url: "https://www.linkedin.com/groups/14309540/",
        title: "Chemical User Forum for Further Education",
        className: styles.chuffed,
        src: CHUFFED
    },
    {
        title: "Puma Scan: Real Time Software Security",
        url: "https://www.pumascan.com/",
        className: styles.pumascan,
        src: Puma
    },
    {
        title: "JAZ-ANZ",
        url: "https://www.jas-anz.org/",
        className: styles.jas_anz,
        src: JasAnz
    },
    {
        title: "ISO 27001 Information Security",
        url: "https://www.cas.com.au/certification-iso27001/",
        className: styles.iso_27001,
        src: ISO27001
    },
    {
        title: "SOC2",
        className: styles.SOC2,
        src: SOC2
    },
    {
        title: "National Fire Prevention Association",
        url: "https://www.nfpa.org/",
        className: styles.nfpa,
        src: NFPA
    },
    {
        title: "ISO 9001",
        url: "https://gccertification.com/certification/iso-9001-qms/",
        className: styles.iso,
        src: GCC
    },
    {
        title: "Nationally Recognised Training",
        url: "https://training.gov.au/Organisation/Details/40617",
        className: styles.nrt,
        src: NRT
    },
    {
        title: "GOVERNOR of VICTORIA 2014 EXPORT AWARDS",
        className: styles.govea,
        src: GOVEA
    }
]