import { Route, Switch, Router, Redirect } from "react-router-dom"
import { Main } from "./main"
import { Login } from "./login"
import { createBrowserHistory } from "history"
import { getCookie } from "../shared/helpers/cookie-helper"
import "antd/dist/antd.css"
import "./styles.less"
import "./global-style.css"

export const routerHistory = createBrowserHistory()

export const App = () => {
  const domain = getCookie("OutbackDomain")
  return (
    <Router history={routerHistory}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/:domain" component={Main} />
        <Route path="/">
          {domain ? <Redirect to={`/${domain}`} /> :
            <Redirect to="/login" />
          }
        </Route>
      </Switch>
    </Router>
  )
}