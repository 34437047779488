import React from "react"
import { LogoChemwatchBeehiveOutback } from "../../shared/components/logos"
import { LoginForm } from "./login-form"
import uuid from "react-uuid"
import { Awards } from "./awards-images/awards"
import styles from "./login.module.css"

export const Login = () => (
	<div className={styles.login_page}>
		<div className={styles.login_panel}>
			<div className={styles.login_panel_inner_wrapper}>
				<div className={styles.login_block}>
					<div className={styles.logo_chemwatch_beehive}>
						<LogoChemwatchBeehiveOutback />
					</div>
					{/*	<Region className={styles.region} />*/}
					<LoginForm />
				</div>
				{/*			
					<div className={styles.info_block}>
						<h1>
							Upcoming Site Update
							<span>April 2021</span>
						</h1>
						<div className={styles.inner}>
							<CustomScroll className={styles.scroll}>
								<ReleaseNotes />
							</CustomScroll>
						</div>
					</div>
				*/}
			</div>
			{/*	<Webinar />	*/}
		</div>
		{/*
			<TwitterFeed />
			<LoginChemwatchEntourage />
		*/}
		<div className={styles.footer}>
			<ul className={styles.social_media}>
				<li>
					<a
						href="https://twitter.com/chemwatchtweet"
						title="Chemwatch Twitter"
						className="twitter"
						target="_blank"
					>
						<svg viewBox="0 0 248 204" fill="#1D9BF0">
							<path d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04
		C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66
		c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64
		c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76
		c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26
		c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"
							/>
						</svg>
					</a>
				</li>
				<li>
					<a
						href="https://www.linkedin.com/company/chemwatch"
						title="Chemwatch Linkedin"
						className="linkedin"
						target="_blank"
					>
						<svg viewBox="0 0 382 382" fill="#0a66c2">
							<path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056  H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806  c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1  s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73  c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079  c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472L341.91,330.654L341.91,330.654z" />
						</svg>
					</a>
				</li>
				<li>
					<p>{`© Chemwatch, ${new Date().getFullYear()}`}</p>
				</li>
			</ul>
			<div className={styles.external_logos}>
				{Awards.map((image) => (
					<a className={image.className} title={image.title} href={image.url} target="_blank" key={uuid()}>
						<img src={image.src} alt={image.title} />
					</a>
				))}
			</div>
		</div>
	</div>

)