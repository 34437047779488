import { Table, Modal, Button, Pagination, Form } from "antd"
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { LANGUAGES, COUNTRIES } from "../../shared/constants"
import { format } from 'date-fns'
import { Cross, DownloadIcon, ShareIcon, VisibilityIcon } from "../../shared/icons"
import { PDFViewer } from 'react-view-pdf'
import styles from "./grid.module.css"
import { getBase64 } from "../../shared/helpers/base64"
import { ShareForm } from "../../shared/components/share-form"

const loadContent = async (domain, request) => {
    try {
        const url = `${process.env.REACT_APP_OUTBACK_API_URL}/${process.env.REACT_APP_STAGE}/renderWithUrl`
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "OutbackDomain": domain
            },
            body: JSON.stringify(request)
        })
        const data = await response.json()
        return data
    } catch (error) {
        return null
    }
}

const shareDocument = async (domain, request) => {
    try {
        const url = `${process.env.REACT_APP_OUTBACK_API_URL}/${process.env.REACT_APP_STAGE}/share`
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "OutbackDomain": domain
            },
            body: JSON.stringify(request)
        })
        const data = await response.json()
        return data
    } catch (error) {
        return null
    }
}

const getBase64FromUrl = async (url) => {
    const res = await fetch(url)
    const blob = await res.blob()
    const result = await getBase64(blob)

    return result.split(",")[1]
}

const downloadDocument = (result, fileName) => {
    const element = document.createElement("a")
    element.href = `data:application/pdf;base64,${encodeURIComponent(result)}`
    element.download = `${fileName}.pdf`
    element.click()
}

export const Grid = ({ data, onChange, setLoading }) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [file, setFile] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [modalLoading, setModalLoading] = useState(false)
    const [shareModalVisible, setShareModalVisible] = useState(false)
    const [shareFormLoading, setShareFormLoading] = useState(false)
    const [shareDisabled, setShareDisabled] = useState(true)
    const [formData, setFormData] = useState(null)
    const [form] = Form.useForm()
    const { domain } = useParams()

    const onValueChange = (changedValues, values) => setFormData({ ...values })

    const disableShare = async () => {
        if (formData?.to && formData?.subject) {
            try {
				await form.validateFields()
                setShareDisabled(false)
			} catch {
				setShareDisabled(true)
			}
        } else {
            setShareDisabled(true)
        }
    }

    useEffect(() => {
        disableShare()
    }, [formData])

    const view = async (row) => {
        setLoading(true)

        const request = row.fileKey
            ? { fileKey: row.fileKey }
            : { datasetKey: row.datasetKey }
        const url = await loadContent(domain, request)
        if (url) {
            setFile(url)
            setSelectedRow(row)
            setModalVisible(true)
        }

        setLoading(false)
    }

    const download = async (row, isModal) => {
        if (isModal) {
            setModalLoading(true)
        }
        else {
            setLoading(true)
        }

        const request = row.fileKey
            ? { fileKey: row.fileKey }
            : { datasetKey: row.datasetKey }
        const url = await loadContent(domain, request)
        const base64 = await getBase64FromUrl(url)
        downloadDocument(base64, row.materialName)

        if (isModal) {
            setModalLoading(false)
        }
        else {
            setLoading(false)
        }
    }
    const share = async (row) => {
        setShareFormLoading(true)
        const body = formData.comments ? `${formData.comments} \n\n Chemwatch Team` : "Chemwatch Team"
        const request = {
            file: {
                name: `${row.materialName}.pdf`,
                fileKey: row.fileKey,
                datasetKey: row.datasetKey
            },
            to: formData.to.split(","),
            replyTo: formData.replyTo,
            subject: formData.subject,
            body

        }
        await shareDocument(domain, request)
        setShareModalVisible(false)
        setShareFormLoading(false)
    }

    const columns = [
        {
            title: 'Material',
            key: 'material',
            render: (row) => {
                const language = LANGUAGES.find(l => l.code === row.languageCode?.split(",")[0])
                const country = COUNTRIES.find(l => l.code === row.countryCode?.split(",")[0])
                const date = format(row.issueDate ? new Date(row.issueDate) : new Date(), 'dd/MM/yyyy')
                return (
                    <div className={styles.name_block}>
                        {
                            (row.fileKey || row.datasetKey) ?
                                <button
                                    className={styles.material_name}
                                    onClick={() => view(row)}
                                >
                                    {row.materialName}
                                </button>
                                :
                                <p
                                    className={styles.material_name}
                                >
                                    {row.materialName}
                                </p>
                        }

                        <p className={styles.vendor_name}>{row.vendorName}</p>
                        <p className={styles.aditional_info}>
                            {country?.name && (
                                <>
                                    {country?.name}
                                    <span className={styles.divider} />
                                </>)
                            }
                            {language?.name}
                            <span className={styles.divider} />
                            {date}
                        </p>
                    </div>
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 300,
            align: "right",
            render: (row) => ((row.fileKey || row.datasetKey) &&
                <div className="grid-buttons">
                    <Button
                        onClick={() => view(row)}
                        className="icon-button view-grid-button"
                    >
                        <VisibilityIcon />
                        View
                    </Button>
                    <Button
                        onClick={() => download(row)}
                        className="icon-button download-grid-button"
                    >
                        <DownloadIcon />
                        Download
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedRow(row)
                            setShareModalVisible(true)
                        }}
                        className="icon-button share-grid-button"
                    >
                        <ShareIcon />
                        Share
                    </Button>
                </div>
            )
        }
    ];

    const handlePagination = async (page, pageSize) => {
        const from = (page - 1) * pageSize;
        const size = pageSize;

        onChange({ from, size })
    }

    const [expanded, setExpanded] = useState(false)
    const expand = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <div className="outback-main-grid-inner">
                <Table
                    size="small"
                    dataSource={data && data.data}
                    columns={columns}
                    rowKey="socketId"
                    pagination={false}
                />
            </div>

            {data.totalResults ?
                <div className="grid-custom-pagination">
                    <Pagination
                        size="small"
                        current={data && data.currentPage}
                        total={data && data.totalResults}
                        position="bottomRight"
                        showSizeChanger={true}
                        pageSizeOptions={["25", "50", "75", "100", "500"]}
                        defaultPageSize={25}
                        showTotal={(total, range) => `${range.join("–")} / ${total}`}
                        onChange={handlePagination}
                    />
                </div>
                : null
            }

            {modalVisible && (
                <Modal
                    centered
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    width={1000}
                    closeIcon={<Cross />}
                    wrapClassName={expanded ? "expanded-modal-window" : ""}
                    title={[
                        <div className="custom-header-for-modal-window-with-expand-button" key="custom-header-for-modal-window-with-expand-button">
                            Document Preview
                            <button
                                type="button"
                                className={expanded ? "custom-expand-collapse-button-for-modal-window collapse" : "custom-expand-collapse-button-for-modal-window"}
                                onClick={expand}
                                title={expanded ? "Collapse Window" : "Expand-Window"}
                            />
                        </div>
                    ]}
                    footer={[
                        <Button
                            type="primary"
                            key="download"
                            onClick={() => download(selectedRow, true)}
                            className="icon-button download-grid-button"
                        >
                            <DownloadIcon />
                            Download
                        </Button>,
                        <Button
                            type="primary"
                            key="share"
                            onClick={() => setShareModalVisible(true)}
                            className="icon-button share-grid-button"
                        >
                            <ShareIcon />
                            Share
                        </Button>,
                        <Button
                            key="back"
                            onClick={() => setModalVisible(false)}
                            className="icon-button"
                        >
                            Cancel
                        </Button>
                    ]}
                >
                    <div className={`${styles.pdf_wrapper} ${modalLoading ? "custom-loader-spinner-type" : ""}`}>
                        <PDFViewer url={file} />
                    </div>
                </Modal>
            )}
            {shareModalVisible && (
                <Modal
                    centered
                    visible={shareModalVisible}
                    onCancel={() => setShareModalVisible(false)}
                    width={480}
                    closeIcon={<Cross />}
                    wrapClassName={expanded ? "expanded-modal-window" : ""}
                    title={[
                        <div>
                            Share
                        </div>
                    ]}
                    footer={[
                        <Button key="share" onClick={() => share(selectedRow)} type="primary" disabled={shareDisabled}>
                            Share
                        </Button>,
                        <Button key="back" onClick={() => setShareModalVisible(false)}>
                            Cancel
                        </Button>
                    ]}
                >
                    <ShareForm form={form} name="share-form" onChange={onValueChange} loading={shareFormLoading} />
                </Modal>
            )}
        </>
    )
}