import React from "react"
import { withRouter, Link } from "react-router-dom"
import { deleteCookie } from "../../helpers/cookie-helper"
import { LogoChemwatchBeehiveOutback } from "../logos"
import { getCookie } from "../../../shared/helpers/cookie-helper"
import styles from "./header.module.css"

export const Header = withRouter(props => {
	const logo = getCookie("Logo")
	const domain = getCookie("OutbackDomain")
	return (
		<header>
			{
				logo && logo !== "undefined" && logo !== "null" ?
					<Link to={`/${domain}`} className={styles.user_logo} onClick={props.clearFilters}>
						<img alt="custom-application-logo" src={logo} />
					</Link> :
					<Link to={`/${domain}`} className={styles.logo_chemwatch_beehive} onClick={props.clearFilters}>
						<LogoChemwatchBeehiveOutback />
					</Link>
			}

			<button
				type="button"
				onClick={() => {
					deleteCookie("OutbackDomain")
					deleteCookie("Logo")
					props.history.push("/login")
				}}
				className={styles.logout}
			>
				Logout
				<svg viewBox="0 0 300 300" fill="currentColor">
					<path d="M119.77,296.952a149.164,149.164,0,0,1-53.636-22.57,150.437,150.437,0,0,1-54.347-66,149.258,149.258,0,0,1-8.74-28.156,151.464,151.464,0,0,1,0-60.46,149.164,149.164,0,0,1,22.57-53.636,151.357,151.357,0,0,1,9.269-12.308A21,21,0,1,1,68.493,79h.19a108,108,0,1,0,162.635,0h.189a21,21,0,1,1,33.606-25.175,150,150,0,0,1,23.1,37.788,149.3,149.3,0,0,1,8.74,28.157,151.465,151.465,0,0,1,0,60.46,149.164,149.164,0,0,1-22.57,53.636,150.441,150.441,0,0,1-66,54.346,149.259,149.259,0,0,1-28.156,8.74,151.465,151.465,0,0,1-60.46,0ZM128,151V22a22,22,0,0,1,44,0V151a22,22,0,0,1-44,0Z" />
				</svg>
			</button>
		</header>
	)
})