/* eslint-disable max-len */
import {
	Form, Input, Alert
} from "antd"
import React from "react"

export const ReportShareModalContext = React.createContext()

const emailValidation = value => {
	const re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (!value.length) { return Promise.reject("Email is required") }
	const emails = value.replace(/\s/g, "").split(",")
	let isValid = true
	emails.forEach(email => {
		if (!re.test(email)) { isValid = false }
	})
	return isValid ? Promise.resolve() : Promise.reject("Invalid email")
}

export const ShareForm = ({
	form, name, onChange, loading
}) => (
	<Form
		className={loading ? "custom-loader-spinner-type" : ""}
		labelCol={{ span: 5 }}
		form={form}
		name={name}
		onValuesChange={onChange}
	>
		<Alert message="Please separate multiple recipients emails with comma(s)" banner />
		<Form.Item
			name="to"
			label="To"
			initialValue=""
			rules={[{ required: true, validator: (_, value) => emailValidation(value) }]}
		>
			<Input placeholder="Please input your recipients addresses" />
		</Form.Item>
		<Form.Item
			name="from"
			// rules={[{
			// 	required: true,
			// 	type: "email",
			// 	message: localize("Please input address from whom the email should be received")
			// }]}
			label="From"
		>
			{/* <Input placeholder={localize("Please input address from whom the email should be received")} /> */}
			<Input disabled placeholder={process.env.REACT_APP_NOREPLY_EMAIL} />
		</Form.Item>
		{/* <Form.Item
			name="Note"
			label={localize("NOTE")}
			className={styles.red}
		>
			<FormattedMessage id="note-if-you-change-from-address" />
		</Form.Item> */}
		<Form.Item
			name="subject"
			rules={[{ required: true, message: "Please input email subject" }]}
			label="Subject"
		>
			<Input placeholder="Please input email subject" />
		</Form.Item>
		<Form.Item
			name="replyTo"
			label="ReplyTo"
			initialValue=""
			rules={[{
				required: false,
				validator: (_, value) => {
					if (value.length === 0) {
						return Promise.resolve()
					}
					return emailValidation(value)
				}
			}]}
		>
			<Input placeholder="Please input an email for a reply" />
		</Form.Item>
		<Form.Item
			name="comments"
			rules={[{ required: false, message: "Please input your comments" }]}
			label="Comments"
		>
			<Input.TextArea rows={4} placeholder="Please input your comments" />
		</Form.Item>
	</Form>
)
