import React, { useState } from "react"
import { Tooltip, Form, Input, Button } from "antd"
import { routerHistory } from "../app"
import { setCookie } from "../../shared/helpers/cookie-helper"
import { getLogo } from "../../shared/helpers/logo"
import { DesktopIcon } from "../../shared/icons"
import { login } from "../../shared/helpers/login"


export const LoginForm = () => {
	const [form] = Form.useForm()
	const [domainName, setDomainName] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const [fields] = useState({
		domainName: ""
	})

	const openApplication = (domain) => {
		routerHistory.push(`/${domain}`)
	}

	const handleSubmit = () => {
		form.validateFields().then(async () => {
			const domain = form.getFieldValue("domain")
			setIsLoading(true)
			const res = await login(domain)
			if (res.status === 200) {
				setCookie("OutbackDomain", domain, 86400)
				await getLogo(domain)
				openApplication(domain)
			} else {
				throw new Error('Incorrect domain');
			}
		}).catch(() => {
			setIsLoading(false)
		})
	}

	return (
		<>
			<Form
				form={form}
				onSubmit={handleSubmit}
				className={`login-form ${isLoading ? "custom-loader-spinner-type" : ""}`}
			>
				<Form.Item
					name="domain"
					rules={[{ required: true, message: 'Please enter domain name' }]}
				>
					<Input
						className={domainName ? "not-empty" : ""}
						prefix={<span className="domain-name" title="Account"><DesktopIcon /></span>}
						placeholder="Account"
						autoComplete="off"
						allowClear
						onChange={e => setDomainName(e.target.value)}
						onPressEnter={handleSubmit}
					/>
				</Form.Item>
			</Form>
			<div className="login-form-buttons-block">
				<Button type="primary" htmlType="submit" onClick={handleSubmit}>
					Login
				</Button>
			</div>
		</>
	)
}